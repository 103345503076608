import React, { FC } from "react";

import { Box, Link, Typography } from "@mui/material";
import Headers from "main/app/modules/shared/layout/header/header";

interface DataGptProps {}

const DataGpt = () => {
  return (
    <>
      <div>
        <Headers pageIndex={7} />
      </div>
      <Box component="main" className="BoxSection">
        <Typography variant="h1" className="heading">
          DataGPT
        </Typography>
        <Typography variant="h1" className="description">
          Also known as “Ask Me Anything: Data”, this app demonstrates how you
          can, given access to user data, ask “anything” about the data. E.g.
          ...
        </Typography>
        <div style={{ margin: "5em", textAlign: "center" }}>
          <span style={{ marginLeft: "1em" }}>
            <Typography variant="subtitle1" className="description">
              Click here to try it out!
              <br />
              <Link href="http://10.253.0.135:8501" target="_blank">
                http://10.253.0.135:8501
              </Link>
              <br />
              <br />
              And leave feedback in any of the following places:
              <br />
              <Link
                href="https://netspendgroup.slack.com/archives/C06K9L1MTCG"
                target="_blank"
              >
                Slack channel: #ask-me-anything-public
              </Link>
              <br />
              <Link
                href="https://docs.google.com/spreadsheets/d/1QiVqqMmarSstoR8NLA5sWRgoVNVDOEy_xvpDDzbntEQ/edit?usp=drive_link"
                target="_blank"
              >
                Ennead Labs Feedback: DataGPT
              </Link>
              <br />
              <br />
              Contact(s):{" "}
              <Link href="mailto:rayyalasomayajula2@netspend.com">
                Rajesh Ayyalasomayajul
              </Link>
              ,&ensp;<Link href="mailto:adubey@netspend.com">Akash Dubey</Link>
              <br />
              Backup Contact(s):{" "}
              <Link href="mailto:djames@netspend.com">Daniel James</Link>,&ensp;
              <Link href="mailto:hketaraju@netspend.com">Harsha Ketaraju</Link>
            </Typography>
          </span>
        </div>
      </Box>
    </>
  );
};

export default DataGpt;
