import React, { FC } from "react";
import Headers from "main/app/modules/shared/layout/header/header";

import { Box, Typography } from "@mui/material";
import logoImg from "main/content/images/enneadlabslogo.png";
import comingSoonIcon from "main/content/images/comingSoonIcon.png";
import "./comingsoon.scss";

interface ComingsoonProps {}

const Comingsoon = () => {
  return (
    <>
      <div>
        <Headers pageIndex={9} />
      </div>
      <Box component="main" className='BoxSection'>
        <div style={{ display: "flex" }}>
          <img
            src={logoImg}
            alt="Logo"
            style={{ width: "120px", height: "120px" }}
          />
          <Typography
            style={{
              fontWeight: "800",
              fontSize: "16px",
              lineHeight: "19.2px",
              marginBlock: "auto",
            }}
          >
            Explore what we are exploring in <br />
            <span style={{ color: "#D2691E" }}> Ouro's Ennead Labs </span>
          </Typography>
        </div>
        <Typography
          component="h1"
          className="comingSoon"
        >
          Stay Tune!!! New Features <img src={comingSoonIcon} style={{ width: "120px", height: "120px" }}/>
        </Typography>
      </Box>
    </>
  );
};

export default Comingsoon;
