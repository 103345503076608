import React, { FC } from "react";
import Headers from "main/app/modules/shared/layout/header/header";
import { Box, Link, Typography } from "@mui/material";
import sampleImg from "main/content/images/docgpt.gif";

interface DocGptProps {}

const DocGpt: FC<DocGptProps> = () => {
  return (
    <>
      <div>
        <Headers pageIndex={6} />
      </div>
      <Box component="main" className="BoxSection">
        <Typography variant="h1" className="heading">
          DocGPT
        </Typography>
        <Typography variant="h1" className="description">
          Also known as “Ask Me Anything: Documents”, this app demonstrates how
          you can, given a set of documents you upload, ask “anything” about
          those documents. E.g. given a set of documents about regulatory
          requirements, you may want to ask things like “what are the penalties
          for violating a requirement?” or “summarize the regulations for me”.
        </Typography>
        <div style={{ margin: "2em", textAlign: "center" }}>
          <img src={sampleImg} alt="DocGPT" style={{ width: "400px", height: "250px"}} />
          <span style={{ marginLeft: "1em" }}>
            <Typography variant="subtitle1" className="description">
              Click here to try it out!
              <br />
              <Link href="http://10.253.0.141:3040/" target="_blank">
                http://10.253.0.141:3040/
              </Link>
              <br />
              <br />
              And leave feedback in any of the following places:
              <br />
              <Link
                href="https://netspendgroup.slack.com/archives/C06K9L1MTCG"
                target="_blank"
              >
                Slack channel: #ask-me-anything-public
              </Link>
              <br />
              <Link
                href="https://docs.google.com/spreadsheets/d/1u6W3i4hG0GRqAPAtE6J_EHcPS4UhzmeAnVgCBX7PT_0/edit?usp=drive_link"
                target="_blank"
              >
                Ennead Labs Feedback: DocGPT Beta 1{" "}
              </Link>
              <br />
              <br />
              Contact(s):{" "}
              <Link href="mailto:sgottiparthi@netspend.com">
                Srikar Gottiparthi
              </Link>
              ,&ensp;<Link href="mailto:asaini@netspend.com">Aman Saini</Link>
              <br />
              Backup Contact(s):{" "}
              <Link href="mailto:djames@netspend.com">Daniel James</Link>,&ensp;
              <Link href="mailto:hketaraju@netspend.com">Harsha Ketaraju</Link>
            </Typography>
          </span>
        </div>
      </Box>
    </>
  );
};

export default DocGpt;
