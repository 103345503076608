import React, { FC } from "react";

import Headers from "main/app/modules/shared/layout/header/header";
import { Box, Typography } from "@mui/material";
import logoImg from "main/content/images/enneadlabslogo.png";
import "./Home.scss";

interface HomeProps {}

const Home = () => {
  return (
    <>
      <div>
        <Headers pageIndex={1} />
      </div>
      <Box component="main" className='BoxSection'>
        <div style={{ display: "flex" }}>
          <img
            src={logoImg}
            alt="Logo"
            style={{ width: "120px", height: "120px" }}
          />
          <Typography
            style={{
              fontWeight: "800",
              fontSize: "16px",
              lineHeight: "19.2px",
              marginBlock: "auto",
            }}
          >
            Explore what we are exploring in <br />
            <span style={{ color: "#D2691E" }}> Ouro's Ennead Labs </span>
          </Typography>
        </div>
        <Typography component="h1"
            className="h1"
            >
            What is Ennead Labs?   
         </Typography>
         <Typography paragraph
         style={{
            width: "471px",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "19.2px",
            marginBlock: "1em",
            fontFamily: "system-ui"
         }}
         >
         Our goal is to act as the experimental laboratory for the company, 
         thinking beyond the bounds of our current business, concepts and infrastructure to create potential for future value.   
      </Typography>
      <Typography variant="subtitle1" gutterBottom
           className="subtitle1"
            >
            This includes, but is not limited to, exploring:
         </Typography>
         <Typography variant="body1" gutterBottom
            className="body1"
            >
            <ul>
               <li>Modern AI/ML techniques to:</li>
               <ol type="a">
                  <li>
                  Anticipate and prevent fraud
                  </li>
                  <li>
                  Provide exceptional, positive and seamless human machine interactions
                  </li>
               </ol>
               <li>
               Alternative infrastructure technologies and processes to improve our agility
               </li>
               <li>
               Performance and accuracy optimizations 
               </li>
               <li>
               The ethical bounds of role, compliance and legal constraints.
               </li>
            </ul>
         </Typography> 
         <Typography variant="subtitle1" gutterBottom
            className="subtitle1"
            >
            The fine print:
         </Typography>
         <Typography variant="body1" gutterBottom
            className="body1"
            >
            <ul>
               <li>This team creates and tests proofs of concepts and generally endeavors
                   to prove out ideas and potential ROI before handing off to product teams.</li>
               <li>
               Product teams then determine how to translate our proofs of concept into products to be released to the market.
               </li>
               <li>
               We advise product teams as appropriate to assist with this translation, but do not own productization or P&L.
               </li>
               <li>
               We have a loosely defined roadmap that is subject to frequent change, depending upon discoveries, 
               changes in the market and innovative visions of what may help grow our company 1+ years into the future. 
               </li>
            </ul>
         </Typography> 
      </Box>
    </>
  );
};

export default Home;
