import "./header.scss";
import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import logoImg from "main/content/images/enneadlabslogowhite.png";
import homeIcon from "main/content/images/homeicon.png";
import usericon from "main/content/images/360icon.png";
import deudnIcon from "main/content/images/deudnIcon.png";
import docGPTIcon from "main/content/images/DocGPTIcon.png";
import dataGPTIcon from "main/content/images/dataGPTIcon.png";
import merchantIcon from "main/content/images/merchantIcon.png";
import adminIcon from "main/content/images/saIcon.png";
import comingSoonIcon from "main/content/images/comingSoonIcon.png";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/footer";
import { Logout } from "@mui/icons-material";
import { useOktaAuth, withOktaAuth } from "@okta/okta-react";
import { Button, Typography } from "@mui/material";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  // width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Header = (props: any) => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = React.useState(null);
  const [name, setName] = React.useState(""); // name of the user

  React.useEffect(() => {
    if (authState && !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info: any) => {
        setUserInfo(info);
        setName(info.name);
        console.log(info);
      });
    }
  }, [authState, oktaAuth]);

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerClose = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  const menuList = [
    {
      id: 1,
      name: "Home",
      icon: homeIcon,
      path: "/",
    },
    {
      id: 2,
      name: "User360 Analytics",
      icon: usericon,
      path: "/userAnalytics",
    },
    {
      id: 3,
      name: "Merchant Data Enhancement",
      icon: merchantIcon,
      path: "/merchantEntropy",
    },
    {
      id: 4,
      name: "Dedun SimBank App",
      icon: deudnIcon,
      path: "/dedunApp",
    },
    {
      id: 5,
      name: "Smart Assistant Admin Console",
      icon: adminIcon,
      path: "/adminConsole",
    },
    {
      id: 6,
      name: "DocGPT",
      icon: docGPTIcon,
      path: "/docGPT",
    },
    {
      id: 7,
      name: "DataGPT",
      icon: dataGPTIcon,
      path: "/dataGPT",
    },
    {
      id: 8,
      name: "OuroGPT",
      icon: dataGPTIcon,
      path: "/ouroGPT",
    },
    {
      id: 9,
      name: "Coming Soon...",
      icon: comingSoonIcon,
      path: "/comingsoon",
    },
  ];
  const navigate = useNavigate();
  const handleClick = (param: any) => {
    if (typeof param === "string") navigate(param);
  };

  //OKTA logout
  const logout = () => {
    props.oktaAuth.signOut();
  };

  return (
    <div style={{ display: "block" }}>
      <div className="app-header">
        <img src={logoImg} alt="Logo" className="logo" />
        <div className="app-name">
          Ennead Labs Showcase
        <div style={{
                position: "fixed",
                fontSize: "14px",
                right: "50px",
              }}>Logged in as {name}</div>
              </div>
      </div>

      <Box sx={{ display: "flex" }}>
        <Drawer variant="permanent" open={open}>
          <Divider />
          <List>
            {menuList.map((menu) => (
              <ListItem key={menu.id} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={props.pageIndex === menu.id}
                  onClick={() => handleClick(menu.path)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <img src={menu.icon} alt="Logo" className="menuIcons"></img>
                  </ListItemIcon>
                  {menu.name.includes("SimBank")
                      ?( <ListItemText
                        disableTypography
                      primary={<div style={{lineHeight: "4px"}}><Typography variant="body2">{menu.name}</Typography>
                      <br />
                      <Typography variant="caption" style={{ fontSize: "11px", lineHeight: "1px"}}>
                        (Smart Assistant, Live CS Agent, Enhanced Transactions)</Typography></div>}
                      sx={{ opacity: open ? 1 : 0 }}/>)
                      : menu.name.includes("Merchant")
                      ? (<ListItemText
                      disableTypography
                    primary={<div style={{lineHeight: "4px"}}><Typography variant="body2" >{menu.name}</Typography>
                    <br />
                    <Typography variant="caption" style={{ fontSize: "11px" ,lineHeight: "1px"}}>
                      (ntropy)</Typography></div>}
                    sx={{ opacity: open ? 1 : 0 }}/>)
                      : <ListItemText
                      primary={menu.name}
                      sx={{ opacity: open ? 1 : 0 }}/>
                    }
                </ListItemButton>
                {menu.name === "OuroGPT" ? (
                  <Divider
                    sx={{
                      borderWidth: "1px",
                    }}
                  />
                ) : null}
              </ListItem>
            ))}
          </List>
          {open === true ? (
            <div
              style={{
                bottom: "100px",
                position: "absolute",
                left: "5px",
              }}
            >
              
              <Button
                variant="text"
                style={{ color: "#0000008a" }}
                onClick={logout}
              >
                <IconButton>
                  <Logout />
                </IconButton>
                <span style={{ textTransform: "none" }}>Logout</span>
              </Button>
            </div>
          ) : (
            <div
              style={{
                bottom: "100px",
                position: "absolute",
                left: "8px",
              }}
            >
              <IconButton onClick={logout}>
                <Logout />
              </IconButton>
            </div>
          )}
          <DrawerHeader
            sx={{
              position: "absolute",
              bottom: "115px",
              right: "8px",
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              {open === false ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
        </Drawer>
        <Footer />
      </Box>
    </div>
  );
};

export default withOktaAuth(Header);
