import { Box, Link, Typography } from "@mui/material";
import React, { FC } from "react";
import Headers from "main/app/modules/shared/layout/header/header";

interface OuroGptProps {}

const OuroGpt = () => {
  return (
    <>
      <div>
        <Headers pageIndex={8} />
      </div>
      <Box component="main" className="BoxSection">
        <Typography variant="h1" className="heading">
          OuroGPT
        </Typography>
        <Typography variant="h1" className="description">
          Internal on prem ChatGPT we can safely use within Ouro. Does not
          communicate with OpenAI or any other external chat system.
        </Typography>
        <div style={{ margin: "5em", textAlign: "center" }}>
          {/* <img src="{sampleImg}" alt="DataGPT" style={{ width: "400px", height: "192px"}} /> */}
          <span style={{ marginLeft: "1em" }}>
            <Typography variant="subtitle1" className="description">
              Click here to try it out!
              <br />
              <Link href="http://10.253.0.152:3032/" target="_blank">
                http://10.253.0.152:3032/
              </Link>
              <br />
              <br />
              And leave feedback in any of the following places:
              <br />
              <Link
                href="https://netspendgroup.slack.com/archives/C06K9L1MTCG"
                target="_blank"
              >
                Slack channel: #ask-me-anything-public
              </Link>
              <br />
              <Link
                href="https://docs.google.com/spreadsheets/d/1kl2GYqPPl1NyfjGpI8hJUk51nzqY2ReXfes9yV7fkOE/edit?usp=drive_link"
                target="_blank"
              >
                Ennead Labs Feedback: OuroGPT
              </Link>
              <br />
              <br />
              Contact(s):{" "}
              <Link href="mailto:sgottiparthi@netspend.com">
                Srikar Gottiparthi
              </Link>
              ,&ensp;<Link href="mailto:asaini@netspend.com">Aman Saini</Link>
              <br />
              Backup Contact(s):{" "}
              <Link href="mailto:djames@netspend.com">Daniel James</Link>,&ensp;
              <Link href="mailto:hketaraju@netspend.com">Harsha Ketaraju</Link>
            </Typography>
          </span>
        </div>
      </Box>
    </>
  );
};

export default OuroGpt;
