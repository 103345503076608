import React, { FC } from "react";
import { Box, Link, Typography } from "@mui/material";
import Headers from "main/app/modules/shared/layout/header/header";
import sampleImg from "main/content/images/merchantsample.gif";

interface MerchantEntropyProps {}

const MerchantEntropy: FC<MerchantEntropyProps> = () => {
  return (
    <>
      <div>
        <Headers pageIndex={3} />
      </div>
      <Box component="main" className="BoxSection">
        <Typography variant="h1" className="heading">
          Merchant Data Enhancement (ntropy)
        </Typography>
        <Typography variant="h1" className="description">
          This tool demonstrates how you can translate the{" "}
          <b>unreadable merchant strings</b> that appear in transactions into
          actual readable and usable information; e.g. readable name, location,
          category, etc.
        </Typography>
        <div style={{ margin: "3em", textAlign: "center" }}>
          <img
            src={sampleImg}
            alt="User360 Analytics"
            style={{
              width: "400px",
              height: "250px",
              backgroundColor: "black",
            }}
          />
          <span style={{ marginLeft: "1em" }}>
            <Typography variant="subtitle1" className="description">
              Click here to try it out!
              <br />
              <Link href="http://10.253.0.149:8504/" target="_blank">
                http://10.253.0.149:8504/
              </Link>
              <br />
              <br />
              And leave feedback in any of the following places:
              <br />
              <Link
                href="https://netspendgroup.slack.com/archives/C06QE53RF0V"
                target="_blank"
              >
                Slack channel: #ennead-labs-public
              </Link>
              <br />
              <Link
                href="https://docs.google.com/spreadsheets/d/1vIB6-zHThKjYRlOoH930EPpNoOokns_xZJxFfeGw9r4/edit#gid=0"
                target="_blank"
              >
                Ennead Labs Feedback: Enhanced Merchant Data with ntropy
              </Link>
              <br />
              <br />
              Contact(s):{" "}
              <Link href="mailto:kkant@netspend.com">Kirti Kant</Link> <br />
              Backup Contact(s):{" "}
              <Link href="mailto:djames@netspend.com">Daniel James</Link>,&ensp;
              <Link href="mailto:hketaraju@netspend.com">Harsha Ketaraju</Link>
            </Typography>
          </span>
        </div>
      </Box>
    </>
  );
};

export default MerchantEntropy;
