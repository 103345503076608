import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./app/modules/Home/Home";
import Comingsoon from "./app/modules/comingsoon/comingsoon";
import AdminConsole from "./app/modules/adminConsole/adminConsole";
import UserAnalytics from "./app/modules/userAnalytics/userAnalytics";
import MerchantEntropy from "./app/modules/merchantEntropy/merchantEntropy";
import DedunApp from "./app/modules/dedunApp/dedunApp";
import DataGpt from "./app/modules/dataGPT/dataGPT";
import DocGpt from "./app/modules/docGPT/docGPT";
import OuroGpt from "./app/modules/ouroGPT/ouroGPT";
import PrivateRoutes from '../PrivateRoutes';
import { LoginCallback } from "@okta/okta-react";


const AppRoutes = () => {
  return (
    <div className="view-routes">
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route index element={<Home />} />
          <Route path="comingsoon" element={<Comingsoon />} />
          <Route path="adminConsole" element={<AdminConsole />} />
          <Route path="userAnalytics" element={<UserAnalytics />} />
          <Route path="merchantEntropy" element={<MerchantEntropy />} />
          <Route path="dedunApp" element={<DedunApp />} />
          <Route path="dataGPT" element={<DataGpt />} />
          <Route path="docGPT" element={<DocGpt />} />
          <Route path="ouroGPT" element={<OuroGpt />} />
        </Route>
        <Route path="/login/callback" element={<LoginCallback />}/>
      </Routes>
    </div>
  );
};

export default AppRoutes;
