import React, { FC } from "react";
import Headers from "main/app/modules/shared/layout/header/header";
import { Box, Link, Typography } from "@mui/material";
import sampleImg from "main/content/images/simbank.gif";

interface DedunAppProps {}

const DedunApp = () => {
  return (
    <>
      <div>
        <Headers pageIndex={4} />
      </div>
      <Box component="main" className="BoxSection">
        <Typography variant="h1" className="heading">
          Dedun SimBank App
        </Typography>
        <Typography variant="h1" className="description">
          The Dedun SimBank application is a simulated banking experience with a
          fictional “Dedun SimBank”. It is meant to serve as a sort of “in vivo”
          testing ground for various experiments and how they might behave in a
          “real world” app. The Smart Assistant and transactions with
          ntropy-enhanced data can be viewed in this application.
        </Typography>
        <div style={{ margin: "-1em", textAlign: "center" }}>
          <img
            src={sampleImg}
            alt="User360 Analytics"
            style={{
              width: "400px",
              height: "250px",
              backgroundColor: "black",
            }}
          />
          <span style={{ marginLeft: "1em" }}>
            <Typography variant="subtitle1" className="description">
              Click here to try it out!
              <br />
              <Link
                href="http://10.253.0.151:3001/"
                target="_blank"
              >
                http://10.253.0.151:3001/
              </Link>
              <br />
              <br />
              And leave feedback in any of the following places:
              <br />
              <Link
                href="https://netspendgroup.slack.com/archives/C06QE53RF0V"
                target="_blank"
              >
                Slack channel: #ennead-labs-public
              </Link>
              <br />
              <Link
                href="https://docs.google.com/spreadsheets/d/16kQ4c4e-wMyNr5A1GJvmPu5P1KnLz089QK50GHSV8VU/edit#gid=870026394"
                target="_blank"
              >
                Ennead Labs Feedback: Dedun SimBank
              </Link>
            </Typography>
            <Typography variant="subtitle1" className="description">
              Follow the links below for more information about this effort:
              <br />
              <Link
                href="https://docs.google.com/presentation/d/1nUghQjZ4o9J4-E2vF2lBLObr46B1X5eKjNKKyeb2_rI/edit#slide=id.g295e16fcc0f_2_431"
                target="_blank"
              >
                Presentation: Ennead Labs - Innovation Infrastructure
              </Link>
              <br />
              <br />
              Contact(s):{" "}
              <Link href="mailto:sbahuguna@nxr.global">Shivam Bahuguna</Link>,&ensp;
              <Link href="mailto:utomar@netspend.com">Urvashi Tomar</Link>
              <br />
              Backup Contact(s):{" "}
              <Link href="mailto:djames@netspend.com">Daniel James</Link>,&ensp;
              <Link href="mailto:hketaraju@netspend.com">Harsha Ketaraju</Link>
            </Typography>
          </span>
        </div>
      </Box>
    </>
  );
};

export default DedunApp;
