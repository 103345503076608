import { Box, Link, Typography } from "@mui/material";
import React, { FC } from "react";

import Headers from "main/app/modules/shared/layout/header/header";
import sampleImg from "main/content/images/adminsample.png";

const AdminConsole = () => {
  return (
    <>
      <div>
        <Headers pageIndex={5} />
      </div>
      <Box component="main" className="BoxSection">
        <Typography variant="h1" className="heading">
          Smart Assistant Admin Console
        </Typography>
        <Typography variant="h1" className="description">
          Smart Assistant Admin console is usable by customer service, and
          securely available via the web, to allow for real-time monitoring and
          configuration of the chatbot, providing various abilities like edit
          prompts and intents, view the past conversation and many more .
        </Typography>
        <div style={{ margin: "3em", textAlign: "center" }}>
          <img
            src={sampleImg}
            alt="User360 Analytics"
            style={{
              width: "400px",
              height: "250px",
              backgroundColor: "black",
            }}
          />
          <span style={{ marginLeft: "1em" }}>
            <Typography variant="subtitle1" className="description">
              Click here to try it out!
              <br />
              <Link
                href="https://admin.console.enneadlabs.com/"
                target="_blank"
              >
                https://admin.console.enneadlabs.com/
              </Link>
              <br />
              <br />
              And leave feedback in any of the following places:
              <br />
              <Link
                href="https://netspendgroup.slack.com/archives/C06QE53RF0V"
                target="_blank"
              >
                Slack channel: #ennead-labs-public
              </Link>
              <br />
              <Link
                href="https://docs.google.com/spreadsheets/d/1M4z31FfcNrFLiV1DJPo757y4Xdn1f6OebKoD4Lkc7qg/edit#gid=870026394"
                target="_blank"
              >
                Ennead Labs Feedback: Smart Assistant 2.0 Admin Console
              </Link>
              <br />
              <br />
              Contact(s):{" "}
              <Link href="mailto:utomar@netspend.com">Urvashi Tomar</Link>,&ensp;
              <Link href="mailto:sbahuguna@nxr.global">Shivam Bahuguna</Link>
              <br />
              Backup Contact(s):{" "}
              <Link href="mailto:djames@netspend.com">Daniel James</Link>,&ensp;
              <Link href="mailto:hketaraju@netspend.com">Harsha Ketaraju</Link>
            </Typography>
          </span>
        </div>
      </Box>
    </>
  );
};

export default AdminConsole;
