import React, { FC } from "react";
import Headers from "main/app/modules/shared/layout/header/header";

import { Box, Link, Typography } from "@mui/material";
import sampleImg from "main/content/images/user360sample.gif";

const UserAnalytics = () => {
  return (
    <>
      <div>
        <Headers pageIndex={2} />
      </div>
      <Box component="main" className="BoxSection">
        <Typography variant="h1" className="heading">
          User 360 Analytics
        </Typography>
        <Typography variant="h1" className="description">
          The User360 Analytics tool presents a variety of ways to visualize
          data about our customers, get a “360 degree” view of user spend habits
          and other behaviors.
        </Typography>
        <div style={{ margin: "5em", textAlign: "center" }}>
          <img
            src={sampleImg}
            alt="User360 Analytics"
            style={{ width: "400px", height: "250px" }}
          />
          <span style={{ marginLeft: "1em" }}>
            <Typography variant="subtitle1" className="description">
              Click here to try it out!
              <br />
              <Link href="http://10.253.0.149:8501/" target="_blank">
                http://10.253.0.149:8501/
              </Link>
              <br />
              <br />
              And leave feedback in any of the following places:
              <br />
              <Link
                href="https://netspendgroup.slack.com/archives/C06JGMN3ACD"
                target="_blank"
              >
                Slack channel: #user360-public
              </Link>
              <br />
              <Link
                href="https://docs.google.com/spreadsheets/d/14DwTTUkI2Nc9rfr9pJoMvmWM8rhlCSBYH0gcaIKvRvU/edit?usp=sharing"
                target="_blank"
              >
                Ennead Labs Feedback: User 360 Analytics
              </Link>
              <br />
              <br />
              Contact(s):{" "}
              <Link href="mailto:kkant@netspend.com">Kirti Kant</Link>
              <br />
              Backup Contact(s):{" "}
              <Link href="mailto:djames@netspend.com">Daniel James</Link>,&ensp;
              <Link href="mailto:hketaraju@netspend.com">Harsha Ketaraju</Link>
            </Typography>
          </span>
        </div>
      </Box>
    </>
  );
};

export default UserAnalytics;
